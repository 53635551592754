import { createBrowserRouter } from "react-router-dom";
import Home from "./Pages/Home";
import RequestAnalysis from "./Pages/RequestAnalysis";
import History from "./Pages/History";
import Analysis from "./Pages/Analysis";
import LatestAnalysis from "./Pages/LatestAnalysis";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "request-analysis",
    element: <RequestAnalysis />,
  },
  {
    path: "analysis/:owner/:repo/:version",
    element: <Analysis />,
  },
  {
    path: "latest-analysis/:owner/:repo",
    element: <LatestAnalysis />,
  },
  {
    path: "history",
    element: <History />,
  },
]);
