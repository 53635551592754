import { ReactElement, ReactNode } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

function NavbarLayout({ child }: { child: ReactNode | ReactElement }) {
  return (
    <div className="flex h-screen w-screen flex-col">
      <div className="flex text-gray-700 h-full w-full">
        <div className="flex flex-col items-center text-center flex-shrink-0 w-16 border-r border-gray-300 bg-gray-200 py-3">
          <Navbar />
        </div>
        <div className="flex flex-col flex-shrink-0 w-full border-r border-gray-300 bg-gray-100 pt-10 text-nowrap">
          {child}
        </div>
      </div>
      <div className="flex h-auto w-full bg-gray-100 border-gray-200 border-2">
        <Footer />
      </div>
    </div>
  );
}

export default NavbarLayout;
