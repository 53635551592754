import { useEffect, useState } from "react";
import FileExplorer from "../Components/StaticFileBrowser/FileExplorer";
import SourceCodeViewer from "../Components/SourceCodeViewer/SourceCodeViewer";
import { AnalysedSource } from "../Domain/analysedSource";
import { Params, useParams } from "react-router-dom";
import StaticProjectAnalysisStatus from "../Components/StaticProjectAnalysisStatus";
import { getServerUrl } from "../config";
import { AnalysedProject } from "../Server/analysedProject";
import SyntaxTree from "../Components/SyntaxTree";
import NavbarAndThreeSectionsLayout2 from "../Layouts/NavbarAndThreeSectionsLayout2";

function Analysis() {
  let { owner, repo, version }: Readonly<Params<string>> = useParams();
  let [source, setSource] = useState<AnalysedSource | null>(null);
  let [project, setProject] = useState<AnalysedProject | null>(null);

  useEffect(() => {
    fetch(`${getServerUrl()}/project/${owner}/${repo}/${version}`).then(
      (response) => {
        response.json().then((project: AnalysedProject) => {
          setProject(project);
        });
      }
    );
  }, []);

  return (
    <NavbarAndThreeSectionsLayout2
      section1={
        project && (
          <FileExplorer
            setSource={setSource}
            rootNode={project.file_tree.nodes[""]}
            tree={project.file_tree!}
          />
        )
      }
      section2={
        project && (
          <div>
            <StaticProjectAnalysisStatus
              source={source}
              owner={owner}
              repo={repo}
            />
            {source && <SourceCodeViewer source={source} />}
          </div>
        )
      }
      section3={project && source && <SyntaxTree source_tree={source.nodes} />}
    />
  );
}

export default Analysis;
