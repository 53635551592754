import { Link } from "react-router-dom";
import logo from "../logo.svg";
import history from "../history.jpg";
import Kofi from "./Kofi";

function Navbar() {
  return (
    <div>
      <div className="my-2">
        <Link to="../" className="bg-gray-200 flex size-12">
          <img src={logo} />
        </Link>
        <div>Home</div>
      </div>
      <hr className="w-9 h-px bg-gray-800 border-0" />
      <div className="my-2">
        <Link to="../history" className="bg-gray-200 flex size-12">
          <img src={history} />
        </Link>
        <div>History</div>
      </div>
      <hr className="w-9 h-px bg-gray-800 border-0" />
    </div>
  );
}

export default Navbar;
