import { AnalysedSource } from "../Domain/analysedSource";

function StaticProjectAnalysisStatus({
  source,
  owner,
  repo,
}: {
  source: AnalysedSource | null;
  owner: string | undefined;
  repo: string | undefined;
}) {
  return (
    <div className="m-6">
      <div>
        <h1 className="text-lg font-semibold leading-none">
          Project: {owner}/{repo}
        </h1>
        {source && source.filePath !== "" && <h2>File: {source.filePath}</h2>}
      </div>
    </div>
  );
}

export default StaticProjectAnalysisStatus;
