import React from "react";
import {
  AnalysedSource,
  SourceNode,
  SourceNodePointer,
  SyntaxSpan,
} from "../Domain/analysedSource";
import Tree from "../CustomTrees/react-d3-tree/lib/esm";

type DrawableNode = {
  name: string;
  children: DrawableNode[];
  attributes: {
    // span: string;
    complexity: string;
  };
};

const transformNode: (
  source_tree: SourceNode[],
  node: SourceNodePointer
) => DrawableNode = (source_tree, node_pointer) => {
  console.log("drawing node for", source_tree[node_pointer.index].name);
  let node = source_tree[node_pointer.index];
  return {
    name: node.name,
    attributes: {
      // span: JSON.stringify(node.syntax_span),
      complexity: node.complexity
        .toPrecision(3)
        .replace(/0+$/, "")
        .replace(/.$/, ""),
    },
    children: node.children.map((x) => transformNode(source_tree, x)),
  };
};
// This is a simplified example of an org chart with a depth of 2.
// Note how deeper levels are defined recursively via the `children` property.
const transform = (source_tree: SourceNode[]) => {
  return transformNode(source_tree, source_tree[0].pointer);

  // const orgChart = {
  //   name: "CEO",
  //   children: [
  //     {
  //       name: "Manager",
  //       attributes: {
  //         department: "Production",
  //       },
  //       children: [
  //         {
  //           name: "Foreman",
  //           attributes: {
  //             department: "Fabrication",
  //           },
  //           children: [
  //             {
  //               name: "Worker",
  //             },
  //           ],
  //         },
  //         {
  //           name: "Foreman",
  //           attributes: {
  //             department: "Assembly",
  //           },
  //           children: [
  //             {
  //               name: "Worker",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // };
};

export default function SyntaxTree({
  source_tree,
}: {
  source_tree: SourceNode[];
}) {
  const drawable_tree = transform(source_tree);
  return (
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <div id="treeWrapper" className="h-full">
      <Tree
        data={drawable_tree}
        orientation="vertical"
        collapsible={true}
        initialDepth={2}
      />
    </div>
  );
}
