import * as React from "react";
import logo from "../../logo.svg";
import { Dispatch, SetStateAction } from "react";
import { getServerUrl } from "../../config";
import { AnalysedSource } from "../../Domain/analysedSource";
import { FileNode } from "../../Server/analysedProject";

function FileListing({
  fileNode,
  setSource,
}: {
  fileNode: FileNode;
  setSource: Dispatch<SetStateAction<AnalysedSource | null>>;
}) {
  let split = fileNode.path.split("/");
  let name = split[split.length - 1];
  console.log(JSON.stringify(fileNode));
  return (
    <div
      onClick={async () =>
        await fetchFileContents(
          fileNode.project_local_path,
          fileNode.file_id,
          setSource
        )
      }
      className="pl-6"
    >
      <div className="flex flex-row bg-slate-200 rounded-md px-2">
        <img src={logo} className="size-5 complexity-indicator" />
        <div className="px-2">
          {name} -{" "}
          {fileNode.complexity === "Infinite"
            ? fileNode.complexity
            : (fileNode.complexity as number).toLocaleString().split(".")[0]}
        </div>
      </div>
    </div>
  );
}

const fetchFileContents = async (
  filePath: string,
  fileId: string,
  setSource: Dispatch<SetStateAction<AnalysedSource | null>>
) => {
  const requestUrl = `${getServerUrl()}/get_file_contents/${fileId}`;
  console.log(requestUrl);
  let response = await fetch(requestUrl);
  const json = await response.json();
  let source: AnalysedSource = {
    nodes: json.tree.nodes,
    content: json.content,
    filePath,
  };
  setSource(source);
};

export default FileListing;
