import Kofi from "./Kofi";

function Footer() {
  return (
    <div className="items-end flex justify-evenly place-items-center w-full">
      <div className="flex my-auto bg-blue-100 border-gray-300 border-2 rounded-md p-6 font-semibold">
        Thank you for being an early alpha user of Boltzmann
      </div>
      <div className="flex my-auto bg-blue-100 border-gray-300 border-2 rounded-md p-6 font-semibold ">
        Features may be unstable and data may get deleted!
      </div>
      <Kofi />
    </div>
  );
}

export default Footer;
