import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import FileListing from "./FileListing";
import DirectoryListing from "./DirectoryListing";
import { AnalysedSource } from "../../Domain/analysedSource";
import { FileNode, FileTree } from "../../Server/analysedProject";

function FileExplorerEntry({
  node,
  tree,
  setSource,
}: {
  node: FileNode;
  tree: FileTree;
  setSource: Dispatch<SetStateAction<AnalysedSource | null>>;
}) {
  return (
    <div className="file-explorer-container">
      {node.children.length == 0 && (
        <FileListing fileNode={node} setSource={setSource} />
      )}
      {node.children.length != 0 && (
        <div>
          <DirectoryListing node={node} tree={tree} setSource={setSource} />
        </div>
      )}
    </div>
  );
}

export default FileExplorerEntry;
