import { useEffect, useState } from "react";
import { Project } from "../Domain/project";
import ProjectListing from "./ProjectListing";
import { getServerUrl } from "../config";

function AnalysedProjects() {
  let [projects, setProjects] = useState([] as Project[]);
  useEffect(() => {
    fetch(`${getServerUrl()}/history`).then((response) => {
      response.json().then((json: Response) => {
        console.log(json);
        let projects: Project[] = json.map((x) => {
          let timestamp = new Date(0);
          timestamp.setMilliseconds(x.analysis_timestamp.nanos_since_epoch);
          return {
            owner: x.owner,
            repo: x.repo,
            version: x.version,
            analysis_timestamp: timestamp,
            id: x._id,
          };
        });
        setProjects(projects);
      });
    });
  }, []);
  return (
    <div>
      {projects.map((x) => (
        <ProjectListing project={x} key={x.id} />
      ))}
    </div>
  );
}

type Response = ResponseProject[];

type ResponseProject = {
  _id: string;
  analysis_timestamp: {
    secs_since_epoch: number;
    nanos_since_epoch: number;
  };
  file_tree: any;
  owner: string;
  repo: string;
  version: string;
};

export default AnalysedProjects;
