import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-typescript";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "./customLineHighlight";
import "./customLineHighlight.css";
import { AnalysedSource } from "../../Domain/analysedSource";

export default function SourceCodeViewer({
  source,
}: {
  source: AnalysedSource;
}) {
  useEffect(() => {
    Prism.highlightAll();
  }, [source.content]);
  let linesCount = source.content.split("\n").length;
  let dataLinesMax = [linesCount];
  let maxComplexity = 0;
  for (var node of source.nodes) {
    if (
      node.syntax_span.end_column == 0 &&
      node.syntax_span.start_column == 0 &&
      node.syntax_span.start_row == 0 &&
      node.syntax_span.end_row == 0
    ) {
      continue;
    }
    let nodeLinesSpan =
      node.syntax_span.end_row - node.syntax_span.start_row + 1;
    let complexityPerLineOfCode = node.complexity / nodeLinesSpan;
    for (
      var row = node.syntax_span.start_row;
      row <= node.syntax_span.end_row;
      row++
    ) {
      if (dataLinesMax[row] === undefined) {
        dataLinesMax[row] = complexityPerLineOfCode;
      } else if (dataLinesMax[row] < complexityPerLineOfCode) {
        dataLinesMax[row] = complexityPerLineOfCode;
      }
      if (complexityPerLineOfCode > maxComplexity) {
        maxComplexity = complexityPerLineOfCode;
      }
    }
  }
  let dataLineArray: string[] = [];
  for (var index in dataLinesMax) {
    if (dataLinesMax[index] === null) {
      continue;
    }
    let lineNumber = parseInt(index) + 1;
    dataLineArray[index] = `${lineNumber}:${
      1 - dataLinesMax[index] / maxComplexity
    }`;
  }

  return (
    <div className="Code">
      <style>{`.token.operator{ background: none; }`}</style>
      <pre data-line={dataLineArray.join(",")}>
        {/* <pre> */}
        <code className="language-ts line-numbers">{source.content}</code>
      </pre>
    </div>
  );
}
