import React from "react";
import logo from "../logo.svg";
import { getServerUrl } from "../config";
import { Form } from "react-router-dom";
import NavbarLayout from "../Layouts/NavbarLayout";
import Input from "../Components/Forms/Input";
import Submit from "../Components/Forms/Submit";

function Home() {
  return (
    <NavbarLayout
      child={
        <div className="">
          <div className="flex items-center justify-center">
            <div className="container flex items-center justify-center flex-col sm:flex-row">
              <div className="w-72 flex relative">
                <img
                  src={logo}
                  className="p-4 app-logo app-logo-large"
                  alt="logo"
                />
              </div>

              <Form
                onSubmit={submit}
                action="../request-analysis"
                className="mx-8 flex flex-col items-center justify-center"
              >
                <div className="sm:col-span-4 block font-semibold text-sm pb-6">
                  Supports cs, py, js, ts, rs & go
                </div>
                <div>
                  <Input name="owner" type="text" label="Owner" />
                  <Input name="repo" type="text" label="Repo" />
                </div>
                <Submit text="Submit" />
              </Form>
            </div>
          </div>
        </div>
      }
    />
  );
}

async function submit(event: React.SyntheticEvent): Promise<void> {
  const target = event.target as unknown as FormItems;
  const owner = target.owner.value;
  const repo = target.repo.value;

  await fetch(`${getServerUrl()}/analyse/${owner}/${repo}`, {
    mode: "no-cors",
  });
}

type FormItems = {
  owner: { value: string };
  repo: { value: string };
};

export default Home;
