function Submit({ text }: { text: string }) {
  return (
    <div className="mt-8">
      <button
        type="submit"
        className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
      >
        {text}
      </button>
    </div>
  );
}

export default Submit;
