import { AnalysisStatus } from "../Pages/RequestAnalysis";

function ProjectAnalysisStatus({
  analysisStatus,
  owner,
  repo,
  latestUpdate,
}: {
  analysisStatus: AnalysisStatus;
  owner: string | null;
  repo: string | null;
  latestUpdate: number;
}) {
  let latestUpdateDate = new Date();
  latestUpdateDate.setTime(latestUpdate);
  console.log(analysisStatus);
  return (
    <div className="m-6">
      <div>
        <h1 className="text-lg font-semibold leading-none">
          Project: {owner}/{repo}
        </h1>
      </div>
      {analysisStatus == AnalysisStatus.Queued && (
        <div className="text-lg leading-none">Analysis pending...</div>
      )}
      {analysisStatus == AnalysisStatus.Processing && (
        <div className="text-lg leading-none">Analysing...</div>
      )}
      {analysisStatus == AnalysisStatus.Complete && (
        <div>
          <div className="text-lg leading-none">Analysis complete!</div>
          <a href={`../latest-analysis/${owner}/${repo}`}>See here</a>
        </div>
      )}
      <div>Last checked at {latestUpdateDate.toLocaleTimeString()}</div>
    </div>
  );
}

export default ProjectAnalysisStatus;
