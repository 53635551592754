import { Project } from "../Domain/project";

function ProjectListing({ project }: { project: Project }) {
  return (
    <a
      className="w-1/2 p-6 mx-auto bg-gray-200 border border-gray-400 rounded-lg shadow hover:bg-gray-300 block"
      href={`../analysis/${project.id}`}
    >
      <div className="flex flex-col col-span-1">
        <h2 className="font-bold text-lg">
          {project.owner}/{project.repo}
        </h2>
        <div className="text-sm">Version: {project.version.slice(0, 8)}</div>
        <div className="text-sm">
          Analysed on {project.analysis_timestamp.toLocaleString()}
        </div>
        <div className="w-fit bg-gray-300  text-gray-800 font-semibold mt-2 py-2 px-4 rounded">
          Explore
        </div>
      </div>
    </a>
  );
}

export default ProjectListing;
