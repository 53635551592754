import { useEffect, useState } from "react";
import ProjectAnalysisStatus from "../Components/ProjectAnalysisStatus";
import NavbarLayout from "../Layouts/NavbarLayout";
import { getServerUrl } from "../config";

function RequestAnalysis() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const owner = urlParams.get("owner");
  const repo = urlParams.get("repo");
  let [latestUpdate, setLatestUpdate] = useState(Date.now());
  let [analysisStatus, setAnalysisStatus] = useState(AnalysisStatus.Queued);

  useEffect(() => {
    const interval = setTimeout(() => {
      fetch(`${getServerUrl()}/analysis_status/${owner}/${repo}`).then(
        (response) => {
          response.json().then((response: AnalysisStatusResponse) => {
            console.log(response);
            let status =
              AnalysisStatus[response.status as keyof typeof AnalysisStatus];
            setAnalysisStatus(status);
          });
        }
      );
      setLatestUpdate(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, [latestUpdate]);

  return (
    <NavbarLayout
      child={
        <ProjectAnalysisStatus
          analysisStatus={analysisStatus}
          owner={owner}
          repo={repo}
          latestUpdate={latestUpdate}
        />
      }
    />
  );
}

type AnalysisStatusResponse = {
  status: string;
};

export enum AnalysisStatus {
  Processing,
  Complete,
  Queued,
}

export default RequestAnalysis;
