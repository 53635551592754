import { Dispatch, SetStateAction, useState } from "react";
import { AnalysedSource } from "../../Domain/analysedSource";
import { FileNode, FileTree } from "../../Server/analysedProject";
import FileExplorerEntry from "./FileExplorerEntry";

function FileExplorer({
  setSource,
  rootNode,
  tree,
}: {
  setSource: Dispatch<SetStateAction<AnalysedSource | null>>;
  rootNode: FileNode;
  tree: FileTree;
}) {
  return (
    <div className="file-explorer">
      <FileExplorerEntry node={rootNode} tree={tree} setSource={setSource} />
    </div>
  );
}

export default FileExplorer;
