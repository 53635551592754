import * as React from "react";
import logo from "../../logo.svg";
import { FileNode, FileTree } from "../../Server/analysedProject";
import FileExplorerEntry from "./FileExplorerEntry";
import { AnalysedSource } from "../../Domain/analysedSource";

function DirectoryListing({
  node,
  tree,
  setSource,
}: {
  node: FileNode;
  tree: FileTree;
  setSource: React.Dispatch<React.SetStateAction<AnalysedSource | null>>;
}) {
  let [showChildren, setShowChildren] = React.useState(false);
  let split = node.path.split("/");
  let name = split[split.length - 1];
  return (
    <div className="px-4">
      <div
        className="flex flex-row bg-slate-100 rounded-md"
        onClick={() => setShowChildren(!showChildren)}
      >
        {showChildren && <div>&#9660;</div>}
        {!showChildren && <div className="-rotate-90">&#9660;</div>}
        <img src={logo} className="ml-2 size-5 complexity-indicator" />
        {node.complexity === undefined ? (
          <div className="px-2">{name}</div>
        ) : (
          <div className="px-2">
            {name} -{" "}
            {node.complexity === "Infinite"
              ? node.complexity
              : (node.complexity as number).toLocaleString().split(".")[0]}
          </div>
        )}
      </div>
      {showChildren &&
        node.children.map((x) => (
          <FileExplorerEntry
            node={tree.nodes[x]}
            tree={tree}
            setSource={setSource}
          />
        ))}
    </div>
  );
}

export default DirectoryListing;
