import { ReactElement, ReactNode } from "react";
import Navbar from "../Components/Navbar";
import Kofi from "../Components/Kofi";

function NavbarAndThreeSectionsLayout({
  section1,
  section2,
  section3,
}: {
  section1: ReactNode | ReactElement;
  section2: ReactNode | ReactElement;
  section3: ReactNode | ReactElement;
}) {
  return (
    <div className="flex w-screen h-screen text-gray-700">
      <div className="flex flex-col items-center text-center flex-shrink-0 w-16 border-r border-gray-300 bg-gray-200 py-3">
        <Navbar />
      </div>
      <div className="flex flex-col flex-shrink-0 w-80 border-r border-gray-300 bg-gray-100 pt-10 text-nowrap overflow-scroll">
        {section1}
      </div>
      <div className="flex flex-col flex-grow bg-gray-100 overflow-y-scroll">
        {section2}
      </div>
      <div className="flex flex-col flex-shrink-0 w-1/4 max-w-xs border-l border-gray-300 bg-gray-100">
        {section3}
      </div>
      <div className="size-40 absolute bottom-0 right-0 content-end">
        <Kofi />
      </div>
    </div>
  );
}

export default NavbarAndThreeSectionsLayout;
